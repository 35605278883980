import { Article } from "../../common/api/api.service";
import { ARTICLE_LOAD, ARTICLE_LOAD_MORE, ARTICLE_RELOAD, ARTICLE_SEARCH, ARTICLE_SELECT_DETAILS, ARTICLE_UNLOAD } from "../actions.types";
import { ADD_ARTICLE, SET_ARTICLE, SET_ARTICLE_PAGE, SET_ARTICLE_SEARCH, SET_ARTICLE_SELECTED, UNSET_ARTICLE } from "../mutations.types";

const state = {
    articles: {},
    selectedArticle: {},
    params: {
        page: 0,
        size: 20,
        q: ''
    },
};

const actions = {
    async [ARTICLE_LOAD]({ commit }) {
        let res = await Article.get(state.params);
        commit(SET_ARTICLE, res.data);
    },
    async [ARTICLE_RELOAD]({ commit }) {
        let mult = state.params.page + 1;
        let multSize = mult * state.params.size;
        let params = {
            page: 0,
            size: multSize,
            q: state.params.q
        };
        let res = await Article.get(params);
        commit(SET_ARTICLE, res.data);
    },
    async [ARTICLE_LOAD_MORE]({ commit }) {
        commit(SET_ARTICLE_PAGE, state.params.page + 1);
        let res = await Article.get(state.params);
        commit(ADD_ARTICLE, res.data);
    },
    async [ARTICLE_SEARCH]({commit}, search) {
        commit(SET_ARTICLE_PAGE, 0);
        commit(SET_ARTICLE_SEARCH, search);
        let res = await Article.get(state.params);
        commit(SET_ARTICLE, res.data);
    },
    [ARTICLE_UNLOAD]({ commit }) {
        commit(UNSET_ARTICLE);
    },
    [ARTICLE_SELECT_DETAILS]({ commit }, article) {
        commit(SET_ARTICLE_SELECTED, article);
    }
};

const mutations = {
    [SET_ARTICLE](state, articles) {
        state.articles = articles;
    },
    [ADD_ARTICLE](state, articles) {
        let articleList = [...state.articles.content, ...articles.content];
        articles.content = articleList;
        state.articles = articles;
    },
    [SET_ARTICLE_PAGE](state, page) {
        state.params.page = page;
    },
    [SET_ARTICLE_SEARCH](state, search) {
        state.params.q = search;
    },
    [UNSET_ARTICLE](state) {
        state.articles = {};
        state.selectedArticle = {};
        state.params.page = 0;
        state.params.size = 20;
        state.params.q = '';
    },
    [SET_ARTICLE_SELECTED](state, article) {
        state.selectedArticle = article;
    }
};

const getters = {
    getArticles() {
        return state.articles;
    },
    getSelectedArticle() {
        return state.selectedArticle;
    },
    getArticlesPage() {
        return state.page;
    },
    getArticlesPageSize() {
        return state.size;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
import { Box } from "../../common/api/api.service";
import {
    BOX_LOAD,
    BOX_LOAD_MORE,
    BOX_RELOAD,
    BOX_SEARCH,
    BOX_SELECT_DETAILS,
    BOX_UNLOAD
} from "../actions.types";
import {
    ADD_BOX,
    SET_BOX,
    SET_BOX_PAGE,
    SET_BOX_SEARCH,
    SET_BOX_SELECTED,
    UNSET_BOX
} from "../mutations.types";

const state = {
    boxes: {},
    selectedBox: {},
    params: {
        page: 0,
        size: 20,
        q: '',
    }
};

const actions = {
    async [BOX_LOAD]({ commit }) {
        let res = await Box.get(state.params);
        commit(SET_BOX, res.data);
    },
    async [BOX_RELOAD]({ commit }) {
        let mult = state.params.page + 1;
        let multSize = mult * state.params.size;
        let params = {
            page: 0,
            size: multSize,
            q: state.params.q
        };
        let res = await Box.get(params);
        commit(SET_BOX, res.data);
    },
    async [BOX_LOAD_MORE]({ commit }) {
        commit(SET_BOX_PAGE, state.params.page + 1);
        let res = await Box.get(state.params);
        commit(ADD_BOX, res.data);
    },
    async [BOX_SEARCH]({ commit }, search = '') {
        commit(SET_BOX_PAGE, 0);
        commit(SET_BOX_SEARCH, search);
        let res = await Box.get(state.params);
        commit(SET_BOX, res.data);
    },
    [BOX_UNLOAD]({ commit }) {
        commit(UNSET_BOX);
    },
    [BOX_SELECT_DETAILS]({ commit }, box) {
        commit(SET_BOX_SELECTED, box);
    }
};

const mutations = {
    [SET_BOX](state, boxes) {
        state.boxes = boxes;
    },
    [UNSET_BOX](state) {
        state.boxes = {};
        state.selectedBox = {};
        state.params.page = 0;
        state.params.size = 20;
        state.params.q = '';
    },
    [ADD_BOX](state, boxes) {
        let boxList = [...state.boxes.content, ...boxes.content];
        boxes.content = boxList;
        state.boxes = boxes;
    },
    [SET_BOX_PAGE](state, page) {
        state.params.page = page;
    },
    [SET_BOX_SEARCH](state, search) {
        state.params.q = search;
    },
    [SET_BOX_SELECTED](state, box) {
        state.selectedBox = box;
    }
};

const getters = {
    getBoxes() {
        return state.boxes;
    },
    getSelectedBox() {
        return state.selectedBox;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
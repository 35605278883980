import { ANALYSIS_CREATE_SELECT_ARTICLE, ANALYSIS_CREATE_SELECT_BOX, ANALYSIS_CREATE_SELECT_PACKING_P } from "../actions.types";
import { SET_ANALYSIS_CREATE_ARTICLES, SET_ANALYSIS_CREATE_BOX, SET_ANALYSIS_CREATE_PACKING_P } from "../mutations.types";

const state = {
    selectedArticles: [],
    selectedBoxes: [],
    selectedProfiles: [],
};

const actions = {
    [ANALYSIS_CREATE_SELECT_ARTICLE]({ commit }, articles) {
        commit(SET_ANALYSIS_CREATE_ARTICLES, articles);
    },
    [ANALYSIS_CREATE_SELECT_BOX]({ commit }, boxes) {
        commit(SET_ANALYSIS_CREATE_BOX, boxes);
    },
    [ANALYSIS_CREATE_SELECT_PACKING_P]({ commit }, profiles) {
        commit(SET_ANALYSIS_CREATE_PACKING_P, profiles);
    }
};

const mutations = {
    [SET_ANALYSIS_CREATE_ARTICLES](state, articles) {
        state.selectedArticles = articles;
    },
    [SET_ANALYSIS_CREATE_BOX](state, boxes) {
        state.selectedBoxes = boxes;
    },
    [SET_ANALYSIS_CREATE_PACKING_P](state, profiles) {
        state.selectedProfiles = profiles;
    },
};

const getters = {
    getAnalysisCreateSelectedArticles() {
        return state.selectedArticles;
    },
    getAnalysisCreateSelectedBoxes() {
        return state.selectedBoxes;
    },
    getAnalysisCreateSelectedProfiles() {
        return state.selectedProfiles;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
<template>
  <div class="app-container">
    <layout />
    <dialog-confirm />
    <dialog-alert />
    <dialog-prompt />
    <toast />
  </div>
</template>

<script>
import Layout from "./components/layout/Layout.vue";

import DialogConfirm from "./components/layout/DialogConfirm.vue";
import DialogAlert from "./components/layout/DialogAlert.vue";
import DialogPrompt from "./components/layout/DialogPromt.vue";
import Toast from "./components/layout/Toast.vue";

export default {
  name: "App",
  components: {
    Layout,
    DialogConfirm,
    DialogAlert,
    DialogPrompt,
    Toast,
  },
};
</script>

<style>
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/home/Home.vue';
import { auth } from './middleware/auth';

import store from '../store';

/**
 * Comprobar si hay token guardado.
 * Ir al Login si no
 */
const defaultMeta = {
  meta: {
    middleware: [
      auth
    ]
  }
};

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   ...defaultMeta
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue'),
  },

  {
    path: '/packing/profile',
    name: 'PackingProfile',
    component: () => import('../views/packingProfile/PackingProfile'),
  },
  {
    path: '/packing/profile/create',
    name: 'PackingProfileCreate',
    component: () => import('../views/packingProfile/PackingProfileCreate'),
  },

  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import('../views/analysis/Analysis'),
  },
  {
    path: '/analysis/info/:id',
    name: 'AnalysisDetails',
    component: () => import('../views/analysis/AnalysisDetails')
  },
  {
    path: '/analysis/create',
    name: 'AnalysisCreate',
    component: () => import('../views/analysis/AnalysisCreate')
  },

  {
    path: '/box',
    name: 'Box',
    component: () => import('../views/box/Box'),
  },
  {
    path: '/box/create',
    name: 'BoxCreate',
    component: () => import('../views/box/BoxCreate'),
  },
  {
    path: '/box/import',
    name: 'BoxImport',
    component: () => import('../views/box/BoxImport'),
  },

  {
    path: '/article',
    name: 'Article',
    component: () => import('../views/article/Article')
  },
  {
    path: '/article/create',
    name: 'ArticleCreate',
    component: () => import('../views/article/ArticleCreate')
  },
  {
    path: '/article/import',
    name: 'ArticleImport',
    component: () => import('../views/article/ArticleImport')
  },

  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/User')
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: () => import('../views/user/UserCreate')
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: () => import('../views/Test')
  // },
  { // Cualquier otra ruta -> Home
    path: '*',
    redirect: '/analysis',
    ...defaultMeta
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store
  };

  return middleware[0]({
    ...context
  });
});

export default router;

import { User } from "../../common/api/api.service";
import {
    USER_LOAD,
    USER_LOAD_MORE,
    USER_RELOAD,
    USER_SEARCH,
    USER_SELECT_DETAILS,
    USER_UNLOAD
} from "../actions.types";
import {
    ADD_USER,
    SET_USER,
    SET_USER_PAGE,
    SET_USER_SEARCH,
    SET_USER_SELECTED,
    UNSET_USER
} from "../mutations.types";

const state = {
    users: {},
    selectedUser: {},
    params: {
        page: 0,
        size: 20,
        q: ''
    }
};

const actions = {
    async [USER_LOAD]({ commit }) {
        let res = await User.get(state.params);
        commit(SET_USER, res.data);
    },
    async [USER_RELOAD]({ commit }) {
        let mult = state.params.page + 1;
        let multSize = mult * state.params.size;
        let params = {
            page: 0,
            size: multSize,
            q: state.params.q
        };
        let res = await User.get(params);
        commit(SET_USER, res.data);
    },
    async [USER_SEARCH]({ commit }, search) {
        commit(SET_USER_PAGE, 0);
        commit(SET_USER_SEARCH, search);
        let res = await User.get(state.params);
        commit(SET_USER, res.data);
    },
    [USER_UNLOAD]({ commit }) {
        commit(UNSET_USER);
    },
    async [USER_LOAD_MORE]({ commit }) {
        commit(SET_USER_PAGE, state.params.page + 1);
        let res = await User.get(state.params);
        commit(ADD_USER, res.data);
    },
    [USER_SELECT_DETAILS]({ commit }, user) {
        commit(SET_USER_SELECTED, user);
    }
};

const mutations = {
    [SET_USER](state, users) {
        state.users = users;
    },
    [ADD_USER](state, users) {
        let userList = [...state.users.content, ...users.content];
        users.content = userList;
        state.users = users;
    },
    [UNSET_USER](state) {
        state.users = {};
        state.selectedUser = {};
        state.params.page = 0;
        state.params.size = 20;
        state.params.q = '';
    },
    [SET_USER_PAGE](state, page) {
        state.params.page = page;
    },
    [SET_USER_SEARCH](state, search) {
        state.params.q = search;
    },
    [SET_USER_SELECTED](state, user) {
        state.selectedUser = user;
    }
};

const getters = {
    getUsers() {
        return state.users;
    },
    getSelectedUser() {
        return state.selectedUser;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};

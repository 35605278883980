export const TOOLBAR_TITLE = 'TOOLBAR_TITLE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_UNLOAD = 'LOGIN_UNLOAD';
export const PROFILE_LOAD = 'PROFILE_LOAD';

// DIALOGS
export const CONFIRM_SHOW = 'CONFIRM_SHOW';
export const CONFIRM_CLOSE = 'CONFIRM_CLOSE';
export const ALERT = 'ALERT';
export const ALERT_CLOSE = 'ALERT_CLOSE';
export const PROMPT_SHOW = 'PROMPT_SHOW';
export const PROMPT_CLOSE = 'PROMPT_CLOSE';
export const TOAST = 'TOAST';
export const TOAST_CLOSE = 'TOAST_CLOSE';

export const PACKING_PROFILE_LOAD = 'PACKING_PROFILE_LOAD';
export const PACKING_PROFILE_LOAD_MORE = 'PACKING_PROFILE_LOAD_MORE';
export const PACKING_PROFILE_SEARCH = 'PACKING_PROFILE_SEARCH';
export const PACKING_PROFILE_RELOAD = 'PACKING_PROFILE_RELOAD';
export const PACKING_PROFILE_UNLOAD = 'PACKING_PROFILE_UNLOAD';
export const PACKING_PROFILE_SELECT_DETAILS = 'PACKING_PROFILE_SELECT_DETAILS';

export const ANALYSIS_LOAD = 'ANALYSIS_LOAD';
export const ANALYSIS_UNLOAD = 'ANALYSIS_UNLOAD';
export const ANALYSIS_PARAMS_UPDATE = 'ANALYSIS_PARAMS_UPDATE';
export const ANALYSIS_DETAILS_LOAD = 'ANALYSIS_DETAILS_LOAD';
export const ANALYSIS_DETAILS_SORT = 'ANALYSIS_DETAILS_SORT';
export const ANALYSIS_DETAILS_UNIQUE_LOAD = 'ANALYSIS_DETAILS_UNIQUE_LOAD';
export const ANALYSIS_DETAILS_UPDATE_MANAGED = 'ANALYSIS_DETAILS_UPDATE_MANAGED';
export const ANALYSIS_DETAILS_UNLOAD = 'ANALYSIS_DETAILS_UNLOAD';

export const ANALYSIS_CREATE_SELECT_ARTICLE = 'ANALYSIS_CREATE_SELECT_ARTICLE';
export const ANALYSIS_CREATE_SELECT_BOX = 'ANALYSIS_CREATE_SELECT_BOX';
export const ANALYSIS_CREATE_SELECT_PACKING_P = 'ANALYSIS_CREATE_SELECT_PACKING_P';

export const ARTICLE_LOAD = 'ARTICLE_LOAD';
export const ARTICLE_LOAD_MORE = 'ARTICLE_LOAD_MORE';
export const ARTICLE_SEARCH = 'ARTICLE_SEARCH';
export const ARTICLE_RELOAD = 'ARTICLE_RELOAD';
export const ARTICLE_UNLOAD = 'ARTICLE_UNLOAD';
export const ARTICLE_SELECT_DETAILS = 'ARTICLE_SELECT_DETAILS';

export const BOX_LOAD = 'BOX_LOAD';
export const BOX_LOAD_MORE = 'BOX_LOAD_MORE';
export const BOX_SEARCH = 'BOX_SEARCH';
export const BOX_RELOAD = 'BOX_RELOAD';
export const BOX_UNLOAD = 'BOX_UNLOAD';
export const BOX_SELECT_DETAILS = 'BOX_SELECT_DETAILS';

export const USER_LOAD = 'USER_LOAD';
export const USER_LOAD_MORE = 'USER_LOAD_MORE';
export const USER_SEARCH = 'USER_SEARCH';
export const USER_RELOAD = 'USER_RELOAD';
export const USER_UNLOAD = 'USER_UNLOAD';
export const USER_SELECT_DETAILS = 'USER_SELECT_DETAILS';

import { version } from '../../package.json';

/**
 * Obtener URL del servidor
 */
function getApiUrl() {
    if (localStorage.getItem('API_URL')) {
        return localStorage.getItem('API_URL');
    } else if (window.webpackHotUpdate) {
        return "http://192.168.10.205:8090/api/v1";
    } else {
        return `${location.protocol}//${document.location.host}/api/v1`;
    }
}

export const API_URL = getApiUrl();
export const VERSION = version;

import { PackingProfile } from "../../common/api/api.service";
import {
    PACKING_PROFILE_LOAD,
    PACKING_PROFILE_LOAD_MORE,
    PACKING_PROFILE_RELOAD,
    PACKING_PROFILE_SEARCH,
    PACKING_PROFILE_SELECT_DETAILS,
    PACKING_PROFILE_UNLOAD
} from "../actions.types";
import {
    ADD_PACKING_PROFILE,
    SET_PACKING_PROFILE,
    SET_PACKING_PROFILE_PAGE,
    SET_PACKING_PROFILE_SEARCH,
    SET_PACKING_SELECTED_PROFILE,
    UNSET_PACKING_PROFILE
} from "../mutations.types";

const state = {
    packingProfiles: {},
    selectedProfile: {},
    params: {
        page: 0,
        size: 20,
        q: '',
    },
};

const actions = {
    async [PACKING_PROFILE_LOAD]({ commit }) {
        let res = await PackingProfile.get(state.params);
        commit(SET_PACKING_PROFILE, res.data);
    },
    async [PACKING_PROFILE_RELOAD]({ commit }) {
        let mult = state.params.page + 1;
        let multSize = mult * state.params.size;
        let params = {
            page: 0,
            size: multSize,
            q: state.params.q
        };
        let res = await PackingProfile.get(params);
        commit(SET_PACKING_PROFILE, res.data);
    },
    async [PACKING_PROFILE_LOAD_MORE]({ commit }) {
        commit(SET_PACKING_PROFILE_PAGE, state.params.page + 1);
        let res = await PackingProfile.get(state.params);
        commit(ADD_PACKING_PROFILE, res.data);
    },
    async [PACKING_PROFILE_SEARCH]({ commit }, search) {
        commit(SET_PACKING_PROFILE_PAGE, 0);
        commit(SET_PACKING_PROFILE_SEARCH, search);
        let res = await PackingProfile.get(state.params);
        commit(SET_PACKING_PROFILE, res.data);
    },

    [PACKING_PROFILE_UNLOAD]({ commit }) {
        commit(UNSET_PACKING_PROFILE);
    },
    [PACKING_PROFILE_SELECT_DETAILS]({ commit }, profile) {
        commit(SET_PACKING_SELECTED_PROFILE, profile);
    }
};

const mutations = {
    [SET_PACKING_PROFILE](state, profiles) {
        state.packingProfiles = profiles;
    },
    [UNSET_PACKING_PROFILE](state) {
        state.packingProfiles = {};
        state.selectedProfile = {};
        state.params.page = 0;
        state.params.size = 20;
        state.params.q = '';
    },
    [ADD_PACKING_PROFILE](state, packingProfiles) {
        let packingProfileList = [...state.packingProfiles.content, ...packingProfiles.content];
        packingProfiles.content = packingProfileList;
        state.packingProfiles = packingProfiles;
    },
    [SET_PACKING_PROFILE_PAGE](state, page) {
        state.params.page = page;
    },
    [SET_PACKING_PROFILE_SEARCH](state, search) {
        state.params.q = search;
    },
    [SET_PACKING_SELECTED_PROFILE](state, profile) {
        state.selectedProfile = profile;
    }
};

const getters = {
    getPackingProfiles() {
        return state.packingProfiles;
    },
    getSelectedProfile() {
        return state.selectedProfile;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};

<template>
  <md-list>
    <!-- <md-list-item :to="{ name: 'Home' }" @click="hide()">
      {{ $t("home") }}
    </md-list-item> -->

    <md-list-item :to="{ name: 'Analysis' }" @click="hide()">
      {{ $t("analysis") }}
    </md-list-item>

    <md-list-item :to="{ name: 'PackingProfile' }" @click="hide()">
      {{ $t("packing_profile") }}
    </md-list-item>

    <md-list-item :to="{ name: 'Box' }" @click="hide()">
      {{ $t("box") }}
    </md-list-item>

    <md-list-item :to="{ name: 'Article' }" @click="hide()">
      {{ $t("article") }}
    </md-list-item>

    <md-list-item :to="{ name: 'User' }" @click="hide()">
      {{ $t("user") }}
    </md-list-item>
  </md-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutLinks",
  computed: {
    ...mapGetters({
      authorities: "getAuthoritiesSections",
    }),
  },
  methods: {
    hide() {
      this.$emit("clicked");
    },
  },
};
</script>
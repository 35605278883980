import ApiService, { Auth } from "../../common/api/api.service";
import { Token } from "../../common/token";
import { LOGIN, LOGOUT, PROFILE_LOAD } from "../actions.types";
import { SET_PROFILE, UNSET_PROFILE } from "../mutations.types";

const state = {
    currentUser: {}
};

const actions = {
    async [LOGIN]({ commit }, credentials) {
        let res = await Auth.login(credentials);
        let token = res.headers["x-auth-token"];
        Token.set(token);

        let user = res.data;

        ApiService.setAuthHeader(token);
        commit(SET_PROFILE, user);
    },
    [LOGOUT]({ commit }) {
        Auth.logout();
        ApiService.setAuthHeader(null);
        Token.remove();
        commit(UNSET_PROFILE);
    },
    [PROFILE_LOAD]({ commit }) {
        Auth.userInfo().then((res) => {
            let user = res.data;
            commit(SET_PROFILE, user);
        });
    }
};

const mutations = {

    [SET_PROFILE](state, profile) {
        state.currentUser = profile;
    },
    [UNSET_PROFILE](state) {
        state.currentUser = {};
    }

};

const getters = {
    getCurrentUser() {
        return state.currentUser;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
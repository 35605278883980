export const TOOLBAR_UPDATE = 'TOOLBAR_UPDATE';

export const SET_PROFILE = 'SET_PROFILE';
export const UNSET_PROFILE = 'UNSET_PROFILE';

export const SET_CONFIRM = 'SET_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';
export const SET_ALERT = 'SET_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SET_PROMPT = 'SET_PROMPT';
export const HIDE_PROMPT = 'HIDE_PROMPT';
export const SET_TOAST = 'SET_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const SET_PACKING_PROFILE = 'SET_PACKING_PROFILE';
export const ADD_PACKING_PROFILE = 'ADD_PACKING_PROFILE';
export const SET_PACKING_PROFILE_PAGE = 'SET_PACKING_PROFILE_PAGE';
export const SET_PACKING_PROFILE_SEARCH = 'SET_PACKING_PROFILE_SEARCH';
export const UNSET_PACKING_PROFILE = 'UNSET_PACKING_PROFILE';
export const SET_PACKING_SELECTED_PROFILE = 'SET_PACKING_SELECTED_PROFILE';

export const SET_ANALYSIS = 'SET_ANALYSIS';
export const UNSET_ANALYSIS = 'UNSET_ANALYSIS';
export const SET_ANALYSIS_PARAMS = 'SET_ANALYSIS_PARAMS';
export const SET_ANALYSIS_DETAILS = 'SET_ANALYSIS_DETAILS';
export const SET_ANALYSIS_DETAILS_UNIQUE = 'SET_ANALYSIS_DETAILS_UNIQUE';
export const SET_ANALYSIS_DETAILS_SORT = 'SET_ANALYSIS_DETAILS_SORT';
export const SET_ANALYSIS_DETAILS_MANAGED = 'SET_ANALYSIS_DETAILS_MANAGED';
export const UNSET_ANALYSIS_DETAILS = 'UNSET_ANALYSIS_DETAILS';

export const SET_ANALYSIS_CREATE_ARTICLES = 'SET_ANALYSIS_CREATE_ARTICLES';
export const SET_ANALYSIS_CREATE_BOX = 'SET_ANALYSIS_CREATE_BOX';
export const SET_ANALYSIS_CREATE_PACKING_P = 'SET_ANALYSIS_CREATE_PACKING_P';

export const SET_ARTICLE = 'SET_ARTICLE';
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const SET_ARTICLE_PAGE = 'SET_ARTICLE_PAGE';
export const SET_ARTICLE_SEARCH = 'SET_ARTICLE_SEARCH';
export const UNSET_ARTICLE = 'UNSET_ARTICLE';
export const SET_ARTICLE_SELECTED = 'SET_ARTICLE_SELECTED';

export const SET_BOX = 'SET_BOX';
export const ADD_BOX = 'ADD_BOX';
export const SET_BOX_PAGE = 'SET_BOX_PAGE';
export const SET_BOX_SEARCH = 'SET_BOX_SEARCH';
export const UNSET_BOX = 'UNSET_BOX';
export const SET_BOX_SELECTED = 'SET_BOX_SELECTED';

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const ADD_USER = 'ADD_USER';
export const SET_USER_PAGE = 'SET_USER_PAGE';
export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const SET_USER_SELECTED = 'SET_USER_SELECTED';
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "../constants";
import { Interceptors } from "./api.interceptor";
import { Token } from "../token";
import qs from 'qs';
import store from "../../store";
import { PROFILE_LOAD } from "../../store/actions.types";

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = API_URL;

		// Interceptor http 401
		Vue.axios.interceptors.response.use(Interceptors.responseSuccess, Interceptors.responseError);

		Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache';

		let token = Token.get();
		if (token) {
			this.setAuthHeader(token);
			store.dispatch(PROFILE_LOAD);
		}
	},

	setAuthHeader(token) {
		Vue.axios.defaults.headers.common['X-Auth-Token'] = token;
		return token;
	},

	setLocale(locale) {
		Vue.axios.defaults.headers.common['Accept-Language'] = locale;
	},

	query(resource, params) {
		return Vue.axios.get(resource, {
			params,
			paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
		});
	},

	get(resource) {
		return Vue.axios.get(resource);
	},

	post(resource, data) {
		return Vue.axios.post(resource, data);
	},

	update(resource, data) {
		return Vue.axios.put(resource, data);
	},

	put(resource, data) {
		return Vue.axios.put(resource, data);
	},

	delete(resource) {
		return Vue.axios.delete(resource,);
	}
};

export default ApiService;

export const Auth = {
	login(credentials) {
		return ApiService.post('/auth/login', credentials);
	},
	logout() {
		return ApiService.get('/auth/logout');
	},
	userInfo() {
		return ApiService.get('/auth/userInfo');
	},
};

export const PackingProfile = {
	get(params) {
		return ApiService.query('/packing/profile', params);
	},
	post(packingProfile) {
		return ApiService.post('/packing/profile', packingProfile);
	},
	put(packingProfile) {
		return ApiService.put('/packing/profile', packingProfile);
	},
	delete(id) {
		return ApiService.delete(`/packing/profile/${id}`);
	}
};

export const Analysis = {
	get(params) {
		return ApiService.query('/analysis', params);
	},
	getOne(id) {
		return ApiService.get(`/analysis/${id}`);
	},
	delete(id) {
		return ApiService.delete(`/analysis/${id}`);
	},
	create(params) {
		return ApiService.post('/vol/calculate/multiple', params);
	},
	updateName(id, name) {
		return ApiService.put(`/analysis/name/${id}/${name}`);
	}
};

export const Box = {
	get(params) {
		return ApiService.query('/box', params);
	},
	getIds() {
		return ApiService.get('/box/ids');
	},
	post(box) {
		return ApiService.post('/box', box);
	},
	put(box) {
		return ApiService.put('/box', box);
	},
	delete(id) {
		return ApiService.delete(`/box/${id}`);
	},
	import(file, hasHeader, separator) {
		return Vue.axios.post(`/box/csv?separator=${separator}&hasHeader=${hasHeader}`, file, { headers: { 'Content-Type': 'multipart/form-data' } });
	}
};

export const Article = {
	get(params) {
		return ApiService.query('/article', params);
	},
	post(article) {
		return ApiService.post('/article', article);
	},
	put(article) {
		return ApiService.put('/article', article);
	},
	delete(id) {
		return ApiService.delete(`/article/${id}`);
	},
	import(file, hasHeader, separator) {
		return Vue.axios.post(`/article/csv?separator=${separator}&hasHeader=${hasHeader}`, file, { headers: { 'Content-Type': 'multipart/form-data' } });
	}
};

export const User = {
	get(params) {
		return ApiService.query('/user', params);
	},
	post(user) {
		return ApiService.post('/user', user);
	},
	put(user) {
		return ApiService.put('/user', user);
	},
	updatePassword(user) {
		return ApiService.post('/user/password', user);
	},
	delete(id) {
		return ApiService.delete(`/user/${id}`);
	}
};
import Vue from 'vue';
import Vuex from 'vuex';

import analysis from './analysis/analysis.module';
import analysisCreate from './analysis/analysisCreate.module';
import article from './article/article.module';
import auth from './auth/auth.module';
import box from './box/box.module';
import layout from './layout/layout.module';
import packingProfile from './packingProfile/packingProfile.module';
import user from './user/user.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    analysis,
    analysisCreate,
    article,
    auth,
    box,
    layout,
    packingProfile,
    user
  }
});

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import ApiService from './common/api/api.service';

import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import VueMaterial from 'vue-material';
import Vuelidate from 'vuelidate';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faBars, faUserCircle, faArrowLeft, faPlus, faRedo,
  faEye, faTrash, faChevronLeft, faChevronRight, faTimes, faSearch,
  faChevronUp, faChevronDown, faUpload, faFileDownload, faEdit
} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import './style.css';

import VueObserveVisibilityPlugin from 'vue-observe-visibility';

Vue.config.productionTip = false;

ApiService.init();

Vue.use(VueMaterial);
Vue.use(Vuelidate);
Vue.use(VueToast);
Vue.use(VueObserveVisibilityPlugin);

// FontAwesome
/**
 *  Utilizar:
 *  <font-awesome-icon icon="bars" />
 */
library.add([
  faBars, faUserCircle, faArrowLeft, faPlus, faRedo,
  faEdit, faEye, faTrash, faChevronLeft, faChevronRight, faTimes,
  faSearch, faChevronUp, faChevronDown, faUpload,
  faQuestionCircle, faFileDownload
]);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');

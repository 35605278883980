import { Analysis } from "../../common/api/api.service";
import {
    ANALYSIS_DETAILS_LOAD,
    ANALYSIS_DETAILS_SORT,
    ANALYSIS_DETAILS_UNIQUE_LOAD,
    ANALYSIS_DETAILS_UNLOAD,
    ANALYSIS_DETAILS_UPDATE_MANAGED,
    ANALYSIS_LOAD,
    ANALYSIS_PARAMS_UPDATE,
    ANALYSIS_UNLOAD
} from "../actions.types";
import {
    SET_ANALYSIS,
    SET_ANALYSIS_DETAILS,
    SET_ANALYSIS_DETAILS_MANAGED,
    SET_ANALYSIS_DETAILS_SORT,
    SET_ANALYSIS_DETAILS_UNIQUE,
    SET_ANALYSIS_PARAMS,
    UNSET_ANALYSIS,
    UNSET_ANALYSIS_DETAILS
} from "../mutations.types";

const state = {
    params: {
        page: 0,
        size: 20,
        q: ''
    },
    analysis: {},
    analysisDetails: {},
    managedResults: [],
    detailsUniques: {}
};

const actions = {
    async [ANALYSIS_LOAD]({ commit }) {
        let res = await Analysis.get(state.params);
        commit(SET_ANALYSIS, res.data);
    },
    async [ANALYSIS_PARAMS_UPDATE]({ commit }, params) {
        commit(SET_ANALYSIS_PARAMS, params);
        let res = await Analysis.get(params);
        commit(SET_ANALYSIS, res.data);
    },
    [ANALYSIS_UNLOAD]({ commit }) {
        commit(UNSET_ANALYSIS);
    },
    [ANALYSIS_DETAILS_SORT]({ commit }, results) {
        commit(SET_ANALYSIS_DETAILS_SORT, results);
    },
    [ANALYSIS_DETAILS_UNIQUE_LOAD]({ commit }, uniques) {
        commit(SET_ANALYSIS_DETAILS_UNIQUE, uniques);
    },
    [ANALYSIS_DETAILS_UPDATE_MANAGED]({ commit }, managed) {
        commit(SET_ANALYSIS_DETAILS_MANAGED, managed);
    },
    async [ANALYSIS_DETAILS_LOAD]({ commit }, id) {
        let res = await Analysis.getOne(id);
        commit(SET_ANALYSIS_DETAILS, res.data);
        commit(SET_ANALYSIS_DETAILS_MANAGED, res.data.results);
    },
    [ANALYSIS_DETAILS_UNLOAD]({ commit }) {
        commit(UNSET_ANALYSIS_DETAILS);
    }
};

const mutations = {
    [SET_ANALYSIS](state, analysis) {
        state.analysis = analysis;
    },
    [UNSET_ANALYSIS](state) {
        state.analysis = {};
        state.params = {
            page: 0,
            size: 20,
            q: ''
        };
    },
    [SET_ANALYSIS_PARAMS](state, params) {
        state.params = params;
    },
    [SET_ANALYSIS_DETAILS](state, analysis) {
        state.analysisDetails = analysis;
    },
    [SET_ANALYSIS_DETAILS_UNIQUE](state, uniques) {
        state.detailsUniques = uniques;
    },
    [SET_ANALYSIS_DETAILS_MANAGED](state, managed) {
        state.managedResults = managed;
    },
    [SET_ANALYSIS_DETAILS_SORT](state, results) {
        state.managedResults = results;
    },
    [UNSET_ANALYSIS_DETAILS](state) {
        state.analysisDetails = {};
        state.managedResults = [];
        state.detailsUniques = {};
    }
};

const getters = {
    getAnalysis() {
        return state.analysis;
    },
    getAnalysisParams() {
        return state.params;
    },
    getAnalysisDetails() {
        return state.analysisDetails;
    },
    getAnalysisDetailsManagedResults() {
        return state.managedResults;
    },
    getAnalysisDetailsUniques() {
        return state.detailsUniques;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};